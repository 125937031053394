import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Header from './Header';
import { getTaskList, setRowData, setTaskDialogValue, getDeleteTask, setTaskEditDialogValue, getDashboard, setTaskListRecord } from '../Redux/Actions/index';
import { connect } from 'react-redux';
import { Paper, Toolbar, Grid, InputAdornment, Button, TextField, Typography, Divider } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
import AddIcon from '@material-ui/icons/Add';
import TaskDialog from '../Portal/CreateTaskDialog';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Chart from '../Component/Chart';
import EditTaskDialog from '../Portal/EditTaskDialog';
import BusyIndicator from '../Reusables/BusyIndicator';
import Hidden from '@material-ui/core/Hidden';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  papercss: {
    marginTop: '45%',
    wordBreak: "break-all",
    overflowY: "auto"
  },
  searchField: {
    width: '14rem'
  },
  button: {
    // marginLeft:'1%',
    backgroundColor: 'royalblue',
    // width: '14rem',
    marginTop: '7px'
  },
  textStyle: {
    color: "#5E7A80",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    paddingLeft: "17px"
  },
  countStyle: {
    color: "#5285EC",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "50px"
  },
  moduleStyle: {
    color: "#B5BFC1",
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "20px",
    paddingBottom: '0',
    paddingTop: '0'
  },
  listText: {
    paddingTop: '0',
    paddingBottom: '0'
  },
  papercss: {
    padding: '15px',
    minHeight: "149px",
    maxHeight: "149px",
  },
  taskStatus: {
    padding: '19px'
  },
  divHeight: {
    minHeight: "149px",
    maxHeight: "149px",
    overflow: "scrollY"
  }
}));

const CheckboxList = (props) => {
  const classes = useStyles();
  const [SearchValue, setSearchValue] = useState('');
  // const [checked, setChecked] = React.useState([0]);
  const { taskListData, dashboardData, taskListDataCopy } = props;

  useEffect(() => {
    console.log("UseEffect Calling...");
    props.getTaskList();
    props.getDashboard();
  }, [])

  const onDeleteTask = (index) => {
    // let lineItem = taskListData.data.tasks[id];
    setSearchValue('');
    let id = taskListDataCopy.data.tasks.findIndex(ele => ele._id === taskListData.data.tasks[index]._id)
    let lineItem = taskListDataCopy.data.tasks[id];
    props.setRowData(lineItem);
    props.getDeleteTask();

  }
  const onEdit = (index) => {
    // let lineItem = taskListData.data.tasks[id];
    setSearchValue('');
    let id = taskListDataCopy.data.tasks.findIndex(ele => ele._id === taskListData.data.tasks[index]._id)
    let lineItem = taskListDataCopy.data.tasks[id];
    props.setRowData(lineItem);
    props.setTaskEditDialogValue(true);
  }
  const addNewTask = () => {
    props.setTaskDialogValue(true);
  }
  const onSearch = (e) => {
    let val = e.target.value.toLowerCase().trim();
    setSearchValue(val);
    // let taskList = Object.assign({},taskListDataCopy)
    let taskList = JSON.parse(JSON.stringify(taskListDataCopy));
    if (val) {
      let data = [...taskListDataCopy.data.tasks]
      let filterTaskList = data.filter(ele => ele.name.toLowerCase().includes(val));
      // let arr = [...taskListDataCopy.data.tasks];
      taskList.data.tasks = filterTaskList;
      props.setTaskListRecord(taskList, taskListDataCopy);
    } else {
      props.setTaskListRecord(taskListDataCopy, taskListDataCopy);
    }

  }
  const handlecheckBox = (e, ind) => {
    let taskList = { ...taskListData };
    // taskList.data.tasks[ind].checked = e.target.checked;
    let id = taskListDataCopy.data.tasks.findIndex(ele => ele._id === taskListData.data.tasks[ind]._id)
    taskListDataCopy.data.tasks[id].checked = e.target.checked;
    // changeOriginalList("checked", e.target.checked, ind)
    // props.setTaskListRecord(taskList);
    props.setTaskListRecord(taskList, taskListDataCopy);
  }
  const changeOriginalList = (property, val, ind) => {
    let taskList = { ...taskListData };
    for (let j = 0; j < taskListDataCopy.length; j++) {
      if (taskList[ind]._id === taskListDataCopy[j]._id) {
        taskListDataCopy[j].data.tasks[property] = val;
        break;
      }
    }
    props.setTaskListRecord(taskList, taskListDataCopy);
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Header />
      </Grid>
      <Grid item style={{ marginTop: '6%' }} xs={12} sm={12} md={12} lg={12}>
      </Grid>
      <Grid tem container spacing={4}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.papercss}>
            <div className={classes.textStyle}>{"Tasks Completed"}</div>
            <div className={classes.taskStatus}>
              {dashboardData.tasksCompleted !== undefined && <>
                <span className={classes.countStyle}>
                  {`${dashboardData.tasksCompleted}`}
                </span>
                <span className={classes.moduleStyle}>
                  {`/${dashboardData.totalTasks}`}
                </span>
              </>}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.papercss}>
            <div className={classes.textStyle}>{"Latest Created Tasks"}</div>
            <div>
              {(Object.keys(dashboardData).length > 0 && taskListData.length !== 0) && dashboardData.latestTasks.map(ele => {
                return (
                  <List component="nav" className={classes.listText}>
                    <ListItem className={classes.moduleStyle}>
                      <ListItemIcon>
                        <FiberManualRecordIcon style={{ color: "#8F9EA2", fontSize: "small" }} />
                      </ListItemIcon>
                      <ListItemText primary={ele.name} style={{ textDecoration: `${taskListDataCopy.data.tasks.find(data => data._id === ele._id) !== undefined && taskListDataCopy.data.tasks.find(data => data._id === ele._id).checked ? "line-through" : ""}` }} />
                    </ListItem>
                  </List>
                )
              })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className={classes.papercss} >
            <Chart dashboardData={dashboardData} />
          </Paper>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} md={12} lg={12}>
        {/* style={{marginTop:"2%",padding:'10%'}} */}
        <Hidden smDown>
          <Grid item xs={12} sm={12} md={1} lg={1} style={{textAlign:'start'}}>
            <Typography className={classes.textStyle} style={{marginTop:'15%'}}>Tasks</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} style={{textAlign:'end'}}>
          <TextField placeholder="Search"
            value={SearchValue}
            onChange={onSearch}
            variant="outlined"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} style={{textAlign:'end'}}>
          <Button
            style={{width:"90%"}}
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={addNewTask}
          >
            New Task
          </Button>
        </Grid>
        </Hidden>
        <Hidden mdUp>
        <Grid item xs={12} sm={12} md={1} lg={1} style={{textAlign:'center'}}>
            <Typography className={classes.textStyle} >Tasks</Typography>
          </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField placeholder="Search"
            style={{width:"100%"}}
            value={SearchValue}
            onChange={onSearch}
            variant="outlined"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} >
          <Button style={{width:"100%"}}
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={addNewTask}
          >
            New Task
          </Button>
        </Grid>
         </Hidden>
      </Grid>
      {/* <Grid container> */}
        <Grid item xs={12} sm={12} ms={12} lg ={12}>
          <Paper style={{maxHeight:'42vh',overflowY:'scroll'}} id="test">
          <List>
            {
              taskListData.length !== 0 && taskListData.data.tasks.map((data, index) => {
                return (<>
                  <ListItem key={data.id}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={data.checked === true}
                        onChange={(e) => handlecheckBox(e, index)}
                        tabIndex={-1}
                        disableRipple
                      // inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText >
                      {data.checked ? <del>{data.name}</del> : data.name}
                    </ListItemText>
                    <IconButton name="edit" edge="end" onClick={() => onEdit(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton name="delete" edge="end" onClick={() => onDeleteTask(index)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </ListItem>
                  {(index!==taskListData.data.tasks.length-1) &&<Divider variant="inset" component="li"/>}

                </>)
              })
            }
          </List>
          </Paper>
          <TaskDialog />
          <EditTaskDialog />
          <BusyIndicator />
        </Grid>
      {/* </Grid> */}
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    taskListData: state.taskReducer.taskList,
    dashboardData: state.taskReducer.dashboardData,
    taskListDataCopy: state.taskReducer.taskListDataCopy

  }
}
export default connect(mapStateToProps, { getTaskList, setRowData, getDashboard, setTaskDialogValue, getDeleteTask, setTaskEditDialogValue, setTaskListRecord })(CheckboxList);
