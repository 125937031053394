import {call,put,takeEvery} from 'redux-saga/effects';
import {Types,setDeleteTask,getTaskList,setBusyIndicator,getDashboard} from '../Actions/index'
import {apiCall} from '../../config/apiCalls';
import {deleteTask} from '../../config/apiRoutes';
import {store} from '../Store';

function* postDeleteTask(action){
    console.log("STORE",store);
    let taskId = store.getState().taskReducer.rowData;
    let url = `${deleteTask}/${taskId._id}`;
    let payload = {id:taskId._id}
    try{
            yield put(setBusyIndicator(true));
            let result = yield call(apiCall,url,'DELETE',payload);
            yield put(setBusyIndicator(false));
            result.flag=true;
            yield put(setDeleteTask(result))
            store.dispatch(getTaskList());
            store.dispatch(getDashboard());
    }
    catch(e){
            console.log("ERROR-TASK-CREATE",e.message);
    }
}

function* workerDeleteSaga(){
    yield takeEvery(Types.GET_DELETE_TASK,postDeleteTask)
}
export default workerDeleteSaga;