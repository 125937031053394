import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import {url} from '../config/apiRoutes';
import {history} from '../history';
import {connect} from 'react-redux';
import {setClearState} from '../Redux/Actions/index'

const useStyles = makeStyles((theme) => ({
 
  appbarcolor:{
    color: '#fff',
    backgroundColor: 'white'
  },
  title: {
    flexGrow: 1,
    marginLeft:'17px',
    color: 'grey',
    fontSize: 'medium'
  },
  logouttitle:{
    flexGrow: 2,
    marginLeft:'17px',
    color: 'grey',
    fontSize: 'medium',
    marginLeft:'56%',
    backgroundColor:'transparent'
  }
}));
const Header = (props)=>{
  const classes = useStyles();

  const handleLogOut = ()=>{
    const loggedInUser = sessionStorage.getItem('User');
    if(loggedInUser){
      sessionStorage.clear();
      props.setClearState();
      // localStorage.removeItem('User');
        history.push('/');
    }
  }
return(
    <AppBar position="fixed" className={classes.appbarcolor}>
    <Toolbar>
      <Avatar alt="profilepic" src={`${url}/images/profile.jpg`}/>
      <Typography variant="h6" className={classes.title}>
        Ali
      </Typography>
      <Button color="inherit" className={classes.logouttitle} onClick={handleLogOut}>Logout</Button>
    </Toolbar>
  </AppBar>
)
}
export default connect(null,{setClearState})(Header);