import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    },
    busyIndicator: {
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[0],
        overflow: 'hidden'
    }
});

class BusyIndicator extends PureComponent {
    render() {
        const { classes, open } = this.props;
        return (
            <Dialog
                classes={{
                    paper: classes.busyIndicator
                }}
                open={open}
            >
                <CircularProgress className={classes.progress} size={100} thickness={4} />
            </Dialog>
        );
    };
}

BusyIndicator.propTypes = {
    open: PropTypes.bool
};
const mapStateToProps = (state)=>{
    return {
        open : state.taskReducer.busyIndValue
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BusyIndicator));
