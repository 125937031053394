import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './Redux/Store';
const rootNode = <Provider store={store}>
  <StrictMode>
    <App />
  </StrictMode>
</Provider>

ReactDOM.render(rootNode, document.getElementById('root'));

