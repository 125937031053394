export const Types = {
    GET_LOGIN_DETAILS : 'GET_LOGIN_DETAILS',
    SET_LOGIN_DETAILS :'SET_LOGIN_DETAILS',
    SET_USER_REQUEST : 'SET_USER_REQUEST',
    SET_TASK_DIALOG_VALUE: 'SET_TASK_DIALOG_VALUE',
    SET_TASK_NAME_TEXT:'SET_TASK_NAME_TEXT',
    CREATE_TASK:'CREATE_TASK',
    SET_TASK_RESULT:'SET_TASK_RESULT',
    GET_TASK_LIST:'GET_TASK_LIST',
    SET_TASK_LIST:'SET_TASK_LIST',
    SET_ROW_DATA:'SET_ROW_DATA',
    GET_DELETE_TASK:'GET_DELETE_TASK',
    SET_DELETE_TASK:'SET_DELETE_TASK',
    GET_DASHBOARD_DETAILS:'GET_DASHBOARD_DETAILS',
    SET_DASHBOARD_DETAILS:'SET_DASHBOARD_DETAILS',
    SET_EDIT_TASK_DIALOG_VALUE:'SET_EDIT_TASK_DIALOG_VALUE',
    GET_EDIT_TASK_LIST:'GET_EDIT_TASK_LIST',
    SET_EDIT_TASK_LIST:'SET_EDIT_TASK_LIST',
    SET_UPDATED_TASK:'SET_UPDATED_TASK',
    SET_BUSY_INDICATOR : 'SET_BUSY_INDICATOR',
    SET_CLEAR_STORE : 'SET_CLEAR_STORE'
}

export const getLoginDetails = ()=>{
    return{
        type:Types.GET_LOGIN_DETAILS
    }
}

export const setLoginDetails = (data)=>{
    return{
        type:Types.SET_LOGIN_DETAILS,
        payload:data
    }
}

export const setName = (...args)=>{
    return{
        type:Types.SET_USER_REQUEST,
        payload:{
            name:args[0],
            id:args[1]
        }
    }
}

export const setTaskDialogValue = (value)=>{
    return{
        type:Types.SET_TASK_DIALOG_VALUE,
        payload:value
    }
}
export const setTaskInputNameText = (value)=>{
    return{
        type:Types.SET_TASK_NAME_TEXT,
        payload:value
    }
}
export const createNewTask = ()=>{
    return{
        type:Types.CREATE_TASK
    }
}
export const setNewTask = (data)=>{
    return{
        type:Types.SET_TASK_RESULT,
        payload:data
    }
}
export const getTaskList = ()=>{
    return{
        type:Types.GET_TASK_LIST
    }
}
export const setTaskListRecord = (data, dataCopy)=>{
    return{
        type:Types.SET_TASK_LIST,
        payload:{
            data,
            dataCopy
        }
    }
}
export const setRowData = (data)=>{
    return{
        type:Types.SET_ROW_DATA,
        payload:data
    }
}
export const getDeleteTask = ()=>{
    return{
        type:Types.GET_DELETE_TASK
    }
}
export const setDeleteTask = ()=>{
    return{
        type:Types.SET_DELETE_TASK
    }
}
export const getDashboard = ()=>{
    return{
        type: Types.GET_DASHBOARD_DETAILS
    }
}
export const setDashboardDetails = (data)=>{
    return{
        type: Types.SET_DASHBOARD_DETAILS,
        payload: data
    }
}
export const setTaskEditDialogValue = (value)=>{
    return{
        type:Types.SET_EDIT_TASK_DIALOG_VALUE,
        payload:value
    }
}
export const getEditTaskList = ()=>{
    return{
        type:Types.GET_EDIT_TASK_LIST,
    }
}
export const setEditTaskList = (data)=>{
    return{
        type:Types.SET_EDIT_TASK_LIST,
        payload:data
    }
}
export const setUpdateTask = (data)=>{
    return{
        type:Types.SET_UPDATED_TASK,
        payload:data
    }
}
export const setBusyIndicator = (val)=>{
    return{
        type:Types.SET_BUSY_INDICATOR,
        payload:val
    }
}
export const setClearState = ()=>{
    return{
        type:Types.SET_CLEAR_STORE
    }
}