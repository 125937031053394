import {call,put,takeEvery, takeLatest} from 'redux-saga/effects';
import {getLoginDetails,setLoginDetails,Types,setBusyIndicator} from '../Actions/index';
import {apiCall} from '../../config/apiCalls';
import {login} from '../../config/apiRoutes';
import {store} from '../Store';
function* getLoginData(action){
    let url = login;
    console.log("STORE INFO",store.getState().taskReducer);
    let data = store.getState().taskReducer.getUserReqData
    let payload={
            "name": data.name,
            "apiKey": data.id
    }
    try{
        yield put(setBusyIndicator(true));
       let loginResponse = yield call(apiCall,url,'POST',payload);
       yield put(setBusyIndicator(true));
       loginResponse.data.Success="S";
       yield put(setLoginDetails(loginResponse.data));
    }
    catch(e){
        console.log("Error",e.response);
    }
}
function* workerLoginSaga(){
    yield takeEvery(Types.GET_LOGIN_DETAILS,getLoginData);
}
export default workerLoginSaga;