import logo from './logo.svg';
import './App.css';
import Login from './Component/Login';
import TaskDetails from './Component/Taskdetials';
import TaskList from './Component/TaskList';
import {Switch,Route,Router,BrowserRouter,HashRouter} from 'react-router-dom';
import {history} from './history';
function App() {
  return (
    <>
    <HashRouter history={history}>
    <Switch>
      <Route path="/" component={Login} exact/>
      <Route path="/taskdetails" component={TaskDetails} exact/>
      <Route path="/taskdashboard" component={TaskList} exact/>
    </Switch>
    </HashRouter>
    </>
  );
}

export default App;
