import { setDeleteTask } from "../Actions"

const initialState={
loginInfo:[],
getUserReqData:{},
taskDialogVal:false,
taskInputText:'',
taskResponse:[],
taskList:[],
taskListDataCopy:[],
rowData:[],
deleteTask:[],
dashboardData:{},
editTaskDialogValue:false,
updateTaskName:'',
busyIndValue:false,
}

const setLoginDetails =(state,action)=>{
    return{
        ...state,
        loginInfo:action.payload
    }
}
const setUserReqData = (state,action)=>{
    return{
        ...state,
        getUserReqData:action.payload
    }
}
const setTaskDialog =(state,action)=>{
    return{
            ...state,
            taskDialogVal:action.payload 
    }
}
const setTaskNameInptText = (state,action)=>{
    return{
        ...state,
        taskInputText:action.payload
    }
}
const setTaskResult = (state,action)=>{
    return{
        ...state,
        taskResponse:action.payload
    }
}
const setTaskListResult = (state,action)=>{
    return{
        ...state,
        taskList:action.payload.data,
        taskListDataCopy:action.payload.dataCopy
    }
}
const setDeleteTaskResult = (state,action)=>{
return{
    ...state,
    deleteTask:action.payload
}
}
const setRowDataResult = (state,action)=>{
    return{
        ...state,
        rowData:action.payload
    }
}
const setDashboardDetails = (state,action)=>{
    return{
        ...state,
        dashboardData:action.payload
    }
}
const setTaskEditDialogValResult = (state,action)=>{
    return{
        ...state,
        editTaskDialogValue:action.payload 
    }
}
const setUpdateTaskValue = (state,action)=>{
    return{
        ...state,
        updateTaskName:action.payload
    }
}
const setBusyInd = (state,action)=>{
    return{
        ...state,
        busyIndValue:action.payload
    }
}
export const taskReducer = (state=initialState,action)=>{
        switch(action.type){
            case 'SET_LOGIN_DETAILS' : return setLoginDetails(state,action);
            case 'SET_USER_REQUEST' : return setUserReqData(state,action);
            case 'SET_TASK_DIALOG_VALUE': return setTaskDialog(state,action);
            case 'SET_TASK_NAME_TEXT': return setTaskNameInptText(state,action);
            case 'SET_TASK_RESULT': return setTaskResult(state,action);
            case 'SET_TASK_LIST': return setTaskListResult(state,action);
            case 'SET_DELETE_TASK': return setDeleteTaskResult(state,action);
            case 'SET_ROW_DATA':return setRowDataResult(state,action);
            case 'SET_DASHBOARD_DETAILS': return setDashboardDetails(state,action);
            case 'SET_EDIT_TASK_DIALOG_VALUE': return setTaskEditDialogValResult(state,action);
            case 'SET_UPDATED_TASK':return setUpdateTaskValue(state,action);
            case 'SET_BUSY_INDICATOR': return setBusyInd(state,action);
            case 'SET_CLEAR_STORE':return {...initialState}
            default: return state;
        }
}