import {call,put,takeEvery} from 'redux-saga/effects';
import {Types,setTaskListRecord,setBusyIndicator} from '../Actions/index';
import{apiCall} from '../../config/apiCalls';
import {tasks} from '../../config/apiRoutes';
function* getTaskListRecord(action){
let url =tasks;
        try{
                yield put(setBusyIndicator(true));
                let result = yield call(apiCall,url);
                
                yield put(setBusyIndicator(false));
                yield put((setTaskListRecord(result, result)));
        }
        catch(e){
                        
        }
}


function* workerTaskListSaga(){
    yield takeEvery(Types.GET_TASK_LIST,getTaskListRecord);
}
export default workerTaskListSaga;