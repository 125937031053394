import {all,fork} from 'redux-saga/effects';
import loginDetailSaga from './loginDetailSaga';
import createTaskSaga from './createTaskSaga';
import taskListSga from './getTaskList';
import getDashboardSaga from './getDashboardDetailsSaga';
import deleteTaskListSaga from './deleteTaskSaga';
import editTaskSaga from './editTaskSaga';
function* taskProcess(){
    yield all([fork(loginDetailSaga),fork(createTaskSaga),fork(taskListSga),
        fork(getDashboardSaga),fork(deleteTaskListSaga),fork(editTaskSaga)])
}
export default taskProcess;