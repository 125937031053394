import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { createPortal } from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Dialog,DialogActions ,DialogContent ,DialogContentText ,DialogTitle,TextField,FlatButton, Button} from '@material-ui/core'
import {setTaskEditDialogValue,getEditTaskList,setUpdateTask} from '../Redux/Actions/index'
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({

    button:{
      marginLeft:'8%',
      backgroundColor:'royalblue'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
const EditTaskDialog = (props) =>{
  const classes = useStyles();
    const {EditRowData,editTaskDialogValue} = props;
    const [updatedTaskName,update]=useState('');
  useEffect(()=>{
    update(EditRowData.name);
  },[EditRowData.name])

    const editTask = ()=>{
        console.log("UpdateTaskName---",updatedTaskName);
        props.setUpdateTask(updatedTaskName);
        props.getEditTaskList();
    }

    const ele = <>
    <Dialog
        title="Dialog With Custom Width"
        open={editTaskDialogValue}
    >
  <DialogTitle id="draggable-dialog-title">
      <strong>
      <EditIcon/>{` Edit Task`}
      </strong>
      <IconButton aria-label="close" className={classes.closeButton} onClick={()=>props.setTaskEditDialogValue(false)}>
      <CloseIcon />
    </IconButton>
    </DialogTitle>
    <DialogContent>
    <TextField
      label="Task Name"
      id="outlined-margin-dense"
      //  defaultValue={EditRowData.name}
      className={classes.textField}
      // helperText="Some important text"
      margin="dense"
      variant="outlined"
      value={updatedTaskName}
      onChange={(e)=>update(e.target.value)}
    />
    </DialogContent>
    <DialogActions>
      <Button
    variant="contained"
    color="secondary"
    className={classes.button}
    startIcon={<SaveIcon />}
    onClick={editTask}
  >
    Save Task
  </Button>
    </DialogActions>
    </Dialog>
</>

return createPortal(ele, document.getElementById('taskEditDialogId'))
}

const mapStateToProps = (state)=>{
  return{
    editTaskDialogValue: state.taskReducer.editTaskDialogValue,
    EditRowData: state.taskReducer.rowData
  }
}

export default connect(mapStateToProps,{setTaskEditDialogValue,getEditTaskList,setUpdateTask})(EditTaskDialog);