import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels'; 

export default class Chart extends Component {
    state={
        label :["Completed Task", "totalTask"]
    }
    render() {
        return (
            <div>
                <Doughnut
                    data={{
                        labels : this.state.label,
                        datasets : [{
                            data:[this.props.dashboardData.tasksCompleted, this.props.dashboardData.totalTasks],
                            backgroundColor:["#5285EC","#E8ECEC"]
                        }]
                    }}
                height ='146'
                option ={{
                    color:'white',
                    color:'red'
                }}
                options={{
                    maintainAspectRatio: false
                }}
                />    
            </div>
        )
    }
}
