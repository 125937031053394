import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid,Paper,Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {connect} from 'react-redux';
import{setTaskDialogValue} from '../Redux/Actions/index';
import TaskDialog from '../Portal/CreateTaskDialog';
import TaskList from '../Component/TaskList';
import Header from './Header';
import {history} from '../history';
import BusyIndicator from '../Reusables/BusyIndicator';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbarcolor:{
    color: '#fff',
    backgroundColor: 'white'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft:'17px',
    color: 'grey',
    fontSize: 'medium'
  },
  logouttitle:{
    flexGrow: 2,
    marginLeft:'17px',
    color: 'grey',
    fontSize: 'medium',
    marginLeft:'56%',
    backgroundColor:'transparent'
  },
  textColor:{
    fontSize:'x-large'
  },
  button:{
    marginLeft:'8%',
    backgroundColor:'royalblue'
  }
}));

const AppBarComponent =(props)=> {
  const classes = useStyles();
  const {taskRecord}=props;
  useEffect(()=>{
    const loggedInUser = sessionStorage.getItem('User');
    if(loggedInUser){
        history.push('/taskdetails');
        return;
    }
    history.push('/');

 },[]);
  return (
    <div className={classes.root}>
      <Header/>
      <Grid container spacing ={2} >
        <Grid item xs={12} sm={12} md={12} lg={12} style={{  position: "absolute",
            top: "0",
            right: "0",
            left: "0",
            bottom: "50%",
            backgroundColor:"#F4F4F6",
            textAlign:"center"}} >
          <Paper style={{float:"none", padding:"90px"}} >
              <strong className={classes.textColor}>
                  You have no task.
              </strong><br/><br/>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={()=>{props.setTaskDialogValue(true)}}
              >
                New Task
              </Button>
              <TaskDialog/>
          </Paper>  
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{
          position: "absolute",
          top: "50%",
          right: "0",
          left: "0",
          bottom:" 0",
          backgroundColor:"#F4F4F6",
          textAlign:"center",
          color:"#FFFFFF",
        }}>
           <Paper  style={{float:"none", padding:"90px"}}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DashboardIcon />}
            onClick={()=>{history.push('/taskdashboard')}}
          >
            GO To Dashboard
          </Button>
          <TaskDialog/>
          {/* <BusyIndicator/> */}
          </Paper> 
        </Grid>
      </Grid>
       {/* <Paper style={{marginTop:'8%',padding:'19%'}}>
      <strong className={classes.textColor}>
      You have no task.
      </strong><br/><br/>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={()=>{props.setTaskDialogValue(true)}}
      >
        New Task
      </Button>
      <TaskDialog/>
      </Paper>  */}
      
     </div>
  );
}
const mapStateToProps = state=>{
  return{
    taskRecord:state.taskReducer.taskList
  }
}
export default connect(mapStateToProps,{setTaskDialogValue}) (AppBarComponent);

