import {call,put,takeEvery} from 'redux-saga/effects';
import {Types,setEditTaskList,getTaskList,setTaskEditDialogValue,setBusyIndicator} from '../Actions/index'
import {apiCall} from '../../config/apiCalls';
import {editTask} from '../../config/apiRoutes';

import {store} from '../Store';

function* postEditTask(action){
    
    let storeData = store.getState().taskReducer;
    let taskId = storeData.rowData;
    let taskName = storeData.updateTaskName;
    let url = `${editTask}/${taskId._id}`;
    let payload = {id:taskId._id,name:taskName}
    try{
            yield put(setBusyIndicator(true));
            let result = yield call(apiCall,url,'PUT',payload);
            yield put(setBusyIndicator(false));
            result.flag=true;
            yield put(setEditTaskList(result));
            store.dispatch(setTaskEditDialogValue(false));
            store.dispatch(getTaskList());
    }
    catch(e){
            console.log("ERROR-TASK-CREATE",e.message);
    }
}

function* workerEditSaga(){
    yield takeEvery(Types.GET_EDIT_TASK_LIST,postEditTask)
}
export default workerEditSaga;