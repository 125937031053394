import {call,put,takeEvery} from 'redux-saga/effects';
import {createNewTask,Types,setNewTask,setBusyIndicator,getTaskList,getDashboard} from '../Actions/index'
import {apiCall} from '../../config/apiCalls';
import {postTasks} from '../../config/apiRoutes';
// import {Types,setNewTask} from '../Actions/index'
import {store} from '../Store';
function* postNewTask(){
    let url = postTasks;
    let data = store.getState().taskReducer.taskInputText;
    let payload = {name:data}
    try{
            yield put(setBusyIndicator(true));
            let result = yield call(apiCall,url,'POST',payload);
            yield put(setBusyIndicator(false));
            result.flag=true;
            yield put(setNewTask(result))
            store.dispatch(getTaskList());
            store.dispatch(getDashboard());
    }
    catch(e){
            console.log("ERROR-TASK-CREATE",e.message);
    }
}

function* workerTaskSaga(){
    yield takeEvery(Types.CREATE_TASK,postNewTask)
}
export default workerTaskSaga;