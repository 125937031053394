import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Dialog,DialogActions ,DialogContent ,DialogContentText ,DialogTitle,TextField,FlatButton, Button} from '@material-ui/core'
import {connect} from 'react-redux';
import {setTaskDialogValue,setTaskInputNameText,createNewTask} from '../Redux/Actions/index'
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import{history} from '../history';

const useStyles = makeStyles((theme) => ({

  button:{
    marginLeft:'8%',
    backgroundColor:'royalblue'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CreateTaskDialog = (props) => {
  const {taskDialogValue,taskResponse} = props;
  const [taskName,setTaskName]=useState('');
  useEffect(()=>{

  },[])
  const submitTask = ()=>{
  props.setTaskInputNameText(taskName);
  props.createNewTask();
  }
  if(taskResponse.flag){
    history.push('/taskdashboard');
    props.setTaskDialogValue(false);
  }
  
  const onClose = () =>{
    props.setTaskDialogValue(false);
  }

  const classes = useStyles();
  
    const ele = <>
        <Dialog
            title="Dialog With Custom Width"
            // actions={actions}
            modal={true}
            open={taskDialogValue}
        >
      <DialogTitle id="draggable-dialog-title">
          <strong>
            {`+ New Task`}
          </strong>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
        <TextField
          label="Task Name"
          id="outlined-margin-dense"
          // defaultValue="Task Name"
          className={classes.textField}
          // helperText="Some important text"
          margin="dense"
          variant="outlined"
          value={taskName}
          onChange={(e)=>setTaskName(e.target.value)}
        />
        </DialogContent>
        <DialogActions>
          <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={submitTask}
      >
        New Task
      </Button>
        </DialogActions>
        </Dialog>
    </>

    return createPortal(ele, document.getElementById('taskDialogId'))
}

const mapStateToProps = (state)=>{
  return{
    taskDialogValue : state.taskReducer.taskDialogVal,
    taskResponse : state.taskReducer.taskResponse
  }
}
export default connect(mapStateToProps,{setTaskDialogValue,setTaskInputNameText,createNewTask}) (CreateTaskDialog);
