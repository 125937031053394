import axios from 'axios';

export const apiCall = async(...args)=>{
    axios.defaults.headers.common.Authorization = 'c3033823be10ec07';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    let newUrl = args[0];
    let params = {
        url:newUrl
    }

    if(args[0].includes('tasks') || args[0].includes('dashboard')){
        axios.defaults.headers.common.Authorization = 'Bearer c3f2b5cce11d00e8056ad086';
    }

    if(args[1] === 'POST' || args[1] === 'DELETE' || args[1]=== 'PUT'){
        axios.defaults.headers.common.Authorization = 'Bearer c3f2b5cce11d00e8056ad086';
        params.method=args[1];
        params.data = args[2];
    }
   

    return await axios(params);
}