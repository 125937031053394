import { call, put, takeEvery } from 'redux-saga/effects';
import { Types, setDashboardDetails, getDashboard,setBusyIndicator } from '../Actions/index';
import { apiCall } from '../../config/apiCalls';
import { dashboard } from '../../config/apiRoutes';
function* getDashboardData(action) {
    try {
        let url = dashboard;
        yield put(setBusyIndicator(true));
        let result = yield call(apiCall, url);
        yield put(setBusyIndicator(false));
        yield put((setDashboardDetails(result.data)));

    }
    catch (e) {
        console.log(e)
    }
}

function* workerDashboardDetails() {
    yield takeEvery(Types.GET_DASHBOARD_DETAILS, getDashboardData);
}
export default workerDashboardDetails;