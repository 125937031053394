import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {getLoginDetails,setName} from '../Redux/Actions/index'
import {connect} from 'react-redux';
import{Link} from 'react-router-dom';
import {history} from '../history';
import BusyIndicator from '../Reusables/BusyIndicator';
import {loginValidation} from '../Validation/Uivalidation';
const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

 const Login = (props)=> {
    const classes = useStyles();

    
    const [uname,setName]=useState({firstName:'',id:''});
    const{loginInfo}=props;
    useEffect(()=>{
       const loggedInUser = sessionStorage.getItem('User');
       if(loggedInUser){
          alert('User is already loggedIn!!!');
           history.push('/taskdetails');
           return;
       }
       history.push('/');

    },[]);

    const onLoginDetails = (e) =>{
        let validation = loginValidation(uname.firstName,uname.id);
        if(validation){
            props.setName(uname.firstName,uname.id);
            props.getLoginDetails();
        }
        else{
            alert("Please fill all details");
        }
       

    }

    if(loginInfo.Success){
        sessionStorage.setItem('User',loginInfo.token.token);
        // sessionStorage.setItem('token',loginInfo.token.token);
        history.push("/taskdetails");
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <form >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="id"
                        label="Id"
                        name="Id"
                        onChange={(e)=>{setName({...uname,id:e.target.value})}}
                        // autoComplete="Id"
                        // autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="Name"
                        label="Name"
                        type="text"
                        id="Name"
                        onChange={(e)=>{setName({...uname,firstName: e.target.value})}}
                        // autoComplete="current-password"
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        // type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onLoginDetails}
                    >
                        Login
                    </Button>
                </form>
                <BusyIndicator/>
            </div>
           
        </Container>
    );
}

const mapStateToProps = (state) =>{
    return{
        loginInfo : state.taskReducer.loginInfo
    }
}

export default connect (mapStateToProps,{getLoginDetails,setName}) (Login)
